import React, { Component } from 'react'
import GoogleMapReact from 'google-map-react'
import { MapPin } from 'react-feather'

let mapkey = ''
if (process.env.GATSBY_MAP_KEY) {
  mapkey = process.env.GATSBY_MAP_KEY
}

class GoogleMap extends Component {
  static defaultProps = {
    zoom: 15
  }

  render() {

    const {lat,lng,name} = this.props.location;
    const center = {lat:parseFloat(lat),lng:parseFloat(lng)}
    

    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: mapkey }}
          defaultCenter={center}
          defaultZoom={this.props.zoom}
        >
          <Marker lat={lat} lng={lng} text={name} />
        </GoogleMapReact>
      </div>
    )
  }
}

export default GoogleMap

const Marker = () => {
  return (
    <div style={{ color: 'red' }}>
      <MapPin />
    </div>
  )
}
