import React, { Fragment } from 'react'

import './Form.css'

class Form extends React.Component {
  static defaultProps = {
    name: '',
    subject: '', // optional subject of the notification email
    action: '',
    successMessage: 'Thanks for your enquiry, we will get back to you soon',
    errorMessage:
      'There is a problem, your message has not been sent, please try contacting us via email'
  }

  state = {
    alert: '',
    disabled: false,
    firstname:'',
    lastname:'',
    emailAddress:'',
    message:''
  }

  componentDidUpdate(){
    if(this.state.alert !== ''){
      //hide the alert banner after 5 secs, store the timeout in a property
      this.fadeOutAlertTimer = setTimeout(() => {
        this.setState({alert:''})
      }, 5000);
    }
  }

  componentWillUnmount(){
    //clean up the timer, so we dont modify state on an unmounted component
    if(this.fadeOutAlertTimer)
      clearTimeout(this.fadeOutAlertTimer);
  }

  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  handleChange = (e) => {
    this.setState({ ...this.state, [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    if (this.state.disabled) return

    const form = e.target;
    this.setState({ disabled: true })
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: this.encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
        subject:this.props.subject
      }),
    })
      .then(res => {
        if (res.ok) {
          return res
        } else {
          throw new Error('Network error')
        }
      })
      .then(() => {
        this.setState({
          alert: this.props.successMessage,
          disabled: false,
          firstname:'',
          lastname:'',
          emailAddress:'',
          message:''
        })
      })
      .catch(err => {
        this.setState({
          disabled: false,
          alert: this.props.errorMessage
        })
      })
  }

  render() {
    const { name, subject, action } = this.props

    return (
      <Fragment>
        <form
          className="Form"
          name={name}
          action={action}
          onSubmit={this.handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          hidden
        >
          <div className="Form--Group">
              <input
                className="Form--Input"
                type="text"
                placeholder="Firstname"
                name="firstname"
                required
                value={this.state.firstname}
                onChange={this.handleChange.bind(this)}
              />
              <input
                className="Form--Input"
                type="text"
                placeholder="Lastname"
                name="lastname"
                required
                value={this.state.lastname}
                onChange={this.handleChange.bind(this)}
              />
          </div>
            <input
              className="Form--Input"
              type="email"
              placeholder="Email"
              name="emailAddress"
              required
              value={this.state.emailAddress}
              onChange={this.handleChange.bind(this)}
            />
            <textarea
              className="Form--Input Form--Textarea"
              placeholder="How can we help?"
              name="message"
              rows="10"
              required
              value={this.state.message}
              onChange={this.handleChange.bind(this)}
            />
          <input type="hidden" name="form-name" value={name} />
          <input type="hidden" name="subject" value={subject} />
          <p hidden>
            <label>
              Don’t fill this out: <input name="bot-field" onChange={this.handleChange.bind(this)} />
            </label>
          </p>
          
          {this.state.alert && (
            <div className="Form--Alert">{this.state.alert}</div>
          )}

          <input
            className="Button Form--SubmitButton"
            type="submit"
            value="Enquire"
            disabled={this.state.disabled}
          />
        </form>
      </Fragment>
    )
  }
}

export default Form
