import React from 'react'
import { MapPin, Smartphone, Mail } from 'react-feather'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import FormSimpleAjax from '../components/FormSimpleAjax'
import Content from '../components/Content'
import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'
import './ContactPage.css'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({
  body,
  title,
  subtitle,
  featuredImage,
  address,
  phone,
  email,
  locations,
  featuredImgGatsby
}) => (
  <main className="Contact">
    <PageHeader
      title={title}
      subtitle={subtitle}
      backgroundImage={featuredImage}
      featuredImgGatsby={featuredImgGatsby}
    />
    <section className="section">
      <div className="container container-split">
        <div>
          <Content source={body} />
          <div className="Contact--Details">
            {locations.length > 0 && (
              <div className="Contact--Details--Item">
                <a
                  className="Contact--Details--Link"
                  href={`${encodeURI(locations[0].mapLink)}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <MapPin /> {address}
                </a>
              </div>
            )}
            {phone && (
              <div className="Contact--Details--Item">
                <a className="Contact--Details--Link" href={`tel:${phone}`}>
                  <Smartphone /> {phone}
                </a>
              </div>
            )}
            {email && (
              <div className="Contact--Details--Item">
                <a className="Contact--Details--Link" href={`mailto:${email}`}>
                  <Mail /> {email}
                </a>
              </div>
            )}
          </div>
        </div>

        <div>
          <FormSimpleAjax
            name="SFX Chicalim Contact-Us Form"
            action="/contact/"
            subject="Response from SFX Chicalim website Contact-Us Form"
          />
        </div>
      </div>
    </section>

    <GoogleMap location={locations[0]} />
  </main>
)

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactPageTemplate
      {...page.frontmatter}
      body={page.html}
      featuredImgGatsby={page.featuredImg}
    />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
        featuredImage
        address
        phone
        email
        locations {
          mapLink
          lat
          lng
        }
      }
      featuredImg {
        childImageSharp {
          fluid(maxWidth: 1600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
